


import request from "@/utils/request.js";



// GET /api/Wechat/GetScenicList
// 旅游景点列表
export function GetScenicList() {
  return request({
    url: "/api/Wechat/GetScenicList",
    method: "get",
    // data: params,
  });
}

// GET /api/Wechat/tqybdata
// 彩云天气预报+实况
export function tqybdata(params) {
  return request({
    url: "/api/Wechat/tqybdata",
    method: "get",
    params,
  });
}

// /api/Wechat/tqybdatas?lat=34.25&lng=116.25
export function tqybdatas(params) {
  return request({
    url: "/api/Wechat/tqybdatas",
    method: "GET",
    params
  });
}