<template>
  <div class="content_box theme_bgText ">
    <div v-if="isDetails" class="travel">
      <div class="title">
        <span>旅游气象</span>
        <!-- <img src="@/assets/最新天气图标/n阵雨.png" alt=""> -->
      </div>
      <ul class="travel_List">
        <li v-for="(item, index) of travelList" :key="index" @click="SelItemInfo(item)">
          <div class="left">
            <img :src="target+item.fmsrc" alt="">
            <div @click.stop="GotoTravel(item)">到这里去</div>
          </div>
          <div class="right">
            <span>{{ item.btitle }}</span>
            <p>{{ item.bindstation }}</p>
            <!-- <div  v-html="item.description"></div> -->
          </div>
        </li>
      </ul>
    </div>
    <scenic-details :pageData="pageData" v-else @change="showTravel" ref="scenicDetails"></scenic-details>
  </div>
</template>

<script>
// import myPicker from '@/components/myPicker'
import scenicDetails from "./scenicDetails.vue"
import { GetScenicList } from "./api";
import { AddFlowInfo } from "@/api/index.js";
export default {
  components: {
    scenicDetails
  },
  name: "travel",
  data() {
    return {
      isDetails: true,
      target:window.imgUrl,
      pageData: {},
      travelList: [
        // { img: require('@/assets/logo.png'), title: '槎龙自然保护区', text: '位于黑龙江省西部乌格尔河下游齐齐哈尔市及富裕、林甸、杜蒙。泰来县交界地城，湿地的周围是草地。农田和人工鱼塘，主要保护对象为丹顶鹤等珍宝及湿地生态系统' },
        // { img: require('@/assets/logo.png'), title: '槎龙自然保护区', text: '位于黑龙江省西部乌格尔河下游齐齐哈尔市及富裕、林甸、杜蒙。泰来县交界地城，湿地的周围是草地。农田和人工鱼塘，主要保护对象为丹顶鹤等珍宝及湿地生态系统' },
        // { img: require('@/assets/logo.png'), title: '槎龙自然保护区', text: '位于黑龙江省西部乌格尔河下游齐齐哈尔市及富裕、林甸、杜蒙。泰来县交界地城，湿地的周围是草地。农田和人工鱼塘，主要保护对象为丹顶鹤等珍宝及湿地生态系统，位于黑龙江省西部乌格尔河下游齐齐哈尔市及富裕、林甸、杜蒙。泰来县交界地城，湿地的周围是草地。农田和人工鱼塘，主要保护对象为丹顶鹤等珍宝及湿地生态系统' },
      ]
    }
  },
  created() {
    this.GetScenicListFun()
    this.addvisit()
  },
  mounted() {

  },
  methods: {
    // 增加访问次数
    async addvisit(){
      const res = await AddFlowInfo({m_Id:'02'});
      console.log(res)
    },
    // 获取数据列表
    async GetScenicListFun(){
      const { data } = await GetScenicList();
      if(data){
        this.travelList = data
      }
    },
    // 选择的 景点
    SelItemInfo(item) {
      this.pageData = item;
      this.isDetails = false
    },
    showTravel() {
      this.isDetails = true
    },
    GotoTravel(item){
    const {location} = item;
      localStorage.setItem("updatePage",1)
     this.$router.push({ path: 'TrafficQiX', query:{location}});
    }
  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.travel_List {
  padding: 10px;
  li {
    display: flex;
    padding: 5px 10px;
    margin-bottom: 15px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    .left {
      flex: 2;
      img {
        width: 100%;
        height: 80px;
      }
      div {
        height: 20px;
        background-color: var(--theme_color);
        text-align: center;
        font-size: 13px;
      }
    }
    .right {
      flex: 4;
      display: flex;
      margin-left: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      span {
        margin-bottom: 8px;
        
      }
      p {
        margin: 0;
        font-size: 13px;
        color: rgba($color: #fff, $alpha: 0.8);
        display: -webkit-box;
        display: -moz-box;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }
  }
}

.travel>.title {
  text-align: center;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);

  img {
    position: absolute;
    right: 3%;
    width: 25px;
  }
}
</style>