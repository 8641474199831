<template>
  <div class="scenic">
    <div class="title">
      <van-icon @click="ReturnPage" name="arrow-left" />
      <div>旅游气象</div>
    </div>
    <div class="scenic-context-box">
      <div class="briefIntroduction" v-html="pageData.description">
      </div>
      <!--六天预报-->
      <div class="black prediction1">
        <p class="title">六天预报</p>
          <div ref="yb1Travel" id="yb1"></div>
          <div ref="yb2Travel" id="yb2"></div>
        <ul class="weekyb">
           <li v-for="item of SevenDaysList" :key="item.time">
              <p>{{item.week}}</p>
              <p class="date">{{item.time}}</p>
              <img :src="item.img1" alt="">
              <img :src="item.img2" alt="" class="night">
              <p>{{item.fx}}<br>{{item.fs}}级</p>
              <!-- <div>
                <p>{{item.kqzl}}</p>
                <span :style="`background:${item.kqzlColor}`" class="color"></span>
              </div> -->
            </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
// import myPicker from '@/components/myPicker'
// import { drawAQL } from "./airQuality.js"
import { computeSunRiseSunSet, getWeeknum } from "@/utils/tool.js"
import { Getfengxiang,Getfengsu } from "@/utils/Getfengxiang.js"
import { Format } from "@/utils/common.js"
import { createCanvas, createWDLine } from "../TianQiYuBao/TianQiYuBao.js"
import { GetTianQi,GetLevel } from "@/utils/Gettianqi.js"
import { tqybdatas } from "./api.js"
export default {
  props:{
    pageData:{}
  },
  components: {
    // myPicker
  },
  name: "radarChart",
  data() {
    return {
      SevenDaysList:[],
    }
  },
  created() {
    console.log(this.pageData)
    this.gettqybdata()
  },
  mounted() {

  },
  methods: {
    ReturnPage() {
      this.$emit('change')
    },

    async gettqybdata(){
      var str = this.pageData.location.split(',');
      const obj = {
        lat:str[1],
        lng:str[0]
      }
      let {data, code, msg} = await tqybdatas(obj);
      if(code != 'ok') return alert(msg);
      const { WeatherYb_24h:daytqyb } = data;
      const SevenDaysYB = ()=>{
        var data3 = daytqyb;
        var aqiArr1 = [],//	aqi
          aqicolorArr1 = [],//aqi对应颜色值
          mintempArr = [],//最小温度
          maxtempArr = [],//最大温度
          skyconArr1 = [],//天气状态图标（白天）
          skyconArr2 = [],//天气状态图标（晚上）
          windSpeed1 = [],//风速
          windDirection1 = [],//风向
          weekArr = getWeeknum(5),//周几
          dateArr = [];//日期
        for (var i = 1; i < 7; i++) {
          // var aqiVal1 = data3.aqi[i].avg;
          // aqiArr1.push(GetLevel(aqiVal1).level)
          // aqicolorArr1.push(GetLevel(aqiVal1).color);
          mintempArr.push(parseInt(data3[i].Temperature_Min));
          maxtempArr.push(parseInt(data3[i].Temperature_Max));
          windSpeed1.push(data3[i].WindPower_Day);
          windDirection1.push(Getfengxiang(data3[i].WindDirection_Day));
          dateArr.push(data3[i].Time.split("/")[1] + "/" + data3[i].Time.split("/")[2]);
          var weaState2 = data3[i].Weather_Day;
          var weaState3 = data3[i].Weather_Night;
          var imgSrc2 = require(`@/assets/最新天气图标/${weaState2}.png`);
          var imgSrc3 = require(`@/assets/最新天气图标/${weaState3}.png`);
          skyconArr1.push(imgSrc2)
          skyconArr2.push(imgSrc3)
        }
         this.SevenDaysList = dateArr.map((it,index)=>{
          return {
            time:it, // 时间
            week:weekArr[index],// 周
            fx:windDirection1[index], // 风向
            fs:windSpeed1[index], // 风速
            img1:skyconArr1[index], // 天气实况图片
            img2:skyconArr2[index], // 天气实况图片
            // kqzl:aqiArr1[index], // 空气质量 评级
            kqzlColor:aqicolorArr1[index] // 空气质量 颜色
          }
        })
          var lineColor1 = "#F0CB73",position1='top';
					createWDLine(this.$refs.yb1Travel,maxtempArr,dateArr,lineColor1,position1)
					var lineColor2 = "#6FC5FE",position2='bottom';
					createWDLine(this.$refs.yb2Travel,mintempArr,dateArr,lineColor2,position2)
      }
      SevenDaysYB();
    }
  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.scenic-context-box {
  padding: 10px;

  .briefIntroduction {
    width: 100%;
    height: 300px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    overflow: auto;
    p {
      margin: 0;
      text-indent: 20px;
      font-size: 13px;
      line-height: 25px;
      color: rgba($color: #fff, $alpha: 0.8);
      overflow: auto;
      height: 100%;
    }
  }

  .prediction1 {
    width: 100%;
    height: 500px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
}

.scenic>.title {
  text-align: center;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
  display: flex;
  align-items: center;

  div {
    width: 90%;
  }
}

#wd,
#yb1,
#yb2 {
  width: 95%;
  height: 200px;
  left: 2%;
}

#yb1,
#yb2 {
  position: absolute;
}

#yb1 {
  top: 25%;
}

#yb2 {
  top: 40%;
}
.toTop {
  position: relative;
  bottom: 0px;
}
/*7天预报*/
.prediction1 {
  p {
    margin: 0;
  }

  .title {
    padding: 5px 0;
  }
}

.prediction1 li {
  width: 16.6%;
  float: left;
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
}

.prediction1 li p.date {
  padding: 4px 0 8px;
  color: rgba(255, 255, 255, 0.7);
}

.prediction1 li img {
  width: 28px;
  display: block;
  margin: 0 auto;
}

.prediction1 li img.night {
  margin: 230px auto 6px;
}

.prediction1 li div {
  margin-top: 8px;
}

.prediction1 li span.color {
  width: 54%;
  height: 4px;
  background: #d4c50e;
  display: inline-block;
  border-radius: 6px;
  float: left;
  margin: 4px 23%;
}

</style>