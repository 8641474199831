
import echarts from "@/utils/echarts";

export 	function createCanvas(tqskCanvasdewr,deg,imgSrc,size) {
  var ctx = tqskCanvasdewr.getContext('2d');
  var x = size,
      y = size +30,
      r = size;
  ctx.beginPath();
  ctx.setLineDash([2, 4]);
  ctx.strokeStyle = "#689CCB";
  ctx.arc(x, y, r, Math.PI, 2 * Math.PI,false);
  ctx.stroke();
  ctx.setLineDash([]);
  ctx.moveTo(0, y);
  ctx.lineTo(x*2, y);
  ctx.strokeStyle = "#689CCB";
  ctx.stroke()
  ctx.beginPath();
  ctx.strokeStyle = "#F6BF38";
  ctx.arc(x, y, r, 180 * Math.PI / 180, (deg+180) * Math.PI / 180,false);
  ctx.stroke();
  var img = document.createElement("img");
  img.src = imgSrc;
  // console.log(imgSrc)
  var x1,x2,y1,y2;
  // console.log(deg)
  if(deg>90){
    deg = 180-deg;
    x2 = r * Math.cos(deg * Math.PI / 180);
    y2 = r * Math.sin(deg * Math.PI / 180);
    x1 = x2<0?x-x2:x+x2;
    y1 = y2>0?y-y2:y+y2;
  }
  else {
    x2 = r * Math.cos(deg * Math.PI / 180);
    y2 = r * Math.sin(deg * Math.PI / 180);
    x1 = x2>0?x-x2:x+x2;
    y1 = y2>0?y-y2:y+y2;
  }
  ctx.beginPath();
  // 1、image：image是画布绘制的图像源，绘制到画布上的元素，可以是canvasElement,imageElement,svgImageElement ,videoElement等一系列具有图像的元素。
  // 2、sx：绘制裁剪的图像源的x 坐标位置；
  // 3、sy：绘制裁剪的图像源的y坐标位置；
  // 4、sWidth：绘制裁剪的图像源的宽度；
  // 5、sHeight：绘制裁剪的图像源的高度；
  // 6、dx：目标源在canvas画布上绘制的左上角的x坐标；
  // 7、dy：目标源在canvas画布上绘制的左上角的y坐标；
  // 8、dWidth：目标源在canvas画布上绘制的宽度，会自动根据图像源截取的宽度对比做缩放；
  // 9、dWidth：目标源在canvas画布上绘制的高度，会自动根据图像源截取的高度对比做缩放；
  // drawImage(image, dx, dy, dw, dh) 在画布指定位置上按原图大小绘制指定大小的图
  // console.log(x1 - 15, y1 - 15, 30, 30);
  ctx.drawImage(img, x1 - 15, y1 - 15, 30, 30);
  ctx.stroke();
  var time = new Date().Format("hh:00");
  ctx.font = "14px Arial";
  ctx.strokeStyle = "#fff";
  ctx.strokeWidth = 1;
  ctx.strokeText(time, x1 - 25, y1 - 20);
  // console.log(ctx);
}

export function createWDLine(odemoId,chartData,xData,lineColor,position) {
  var myChart = echarts.init(odemoId);
  var option = {
      xAxis: {
          show: false,
          type: 'category',
          data: xData
      },
      grid:{
          left:'1%',
          right:'1%',
          top:'20%',
          bottom:'30%'
      },
      yAxis: {
          show: false,
          // type: 'log',
          type: 'value',
      },
      series: [{
          data: chartData,
          // data: [-40,-30,-20,-13,-15,-14,40],
          itemStyle: {
              normal: {
                  color: lineColor
              }
          },
          label: {
              normal: {
                  show: true,
                  position: position,
                  color:'#fff',
                  formatter:function(p){
                      return p.value+"°";
                  }
              }
          },
          symbol:'circle',
          symbolSize:10,
          lineStyle: {
              normal: {
                  color: lineColor,
                  width: 1,
                  type: 'solid'
              }
          },
          type: 'line'
      }]
  };
  myChart.setOption(option);
  return myChart
}